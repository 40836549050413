import styled from "styled-components/macro";
import { LeadContainer } from "../common/lead.styles";
import { MEDIA_QUERY_LIMITS } from "../../../config";

export const LeadProgramContainer = styled(LeadContainer)`
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 90%;
    width: 100%;
    // background: var(--salmonColor);
  }
`;

export const EpisodeInfoContainer = styled.div``;

export const EpisodeTitleContainer = styled.div`
  padding: var(--root-vertical-space-1) var(--root-horizontal-space-3);
  text-align: left;
  background: var(--root-background-episode-info-card);

  @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding: var(--root-vertical-space-1) var(--root-horizontal-space-3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid var(--secondaryColor);
    border-bottom: 3px solid var(--secondaryColor);
    background: var(--root-background-episode-info-card);
  }
`;

export const SeasonContainer = styled.div`
  display: flex;
  gap: var(--root-horizontal-space-1);
  margin-bottom: var(--root-vertical-space-0-rem);
  align-items: center;
`;

export const EpisodeDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: var(--root-vertical-space-1) var(--root-horizontal-space-3);

  @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding-top: max(var(--root-vertical-space-1), 10px);
    padding-bottom: max(var(--root-vertical-space-1), 10px);

    border-bottom: 3px solid var(--secondaryColor);
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: min(3.229166667vw, 62px);
`;

export const EpisodeDescription = styled.div`
  --font-size: var(--h3-size);
  text-align: left;
`;
