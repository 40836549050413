import styled, { css } from "styled-components/macro";
import * as Separator from "@radix-ui/react-separator";

import { MEDIA_QUERY_LIMITS } from "../../../config";

import { MoveContainer as MoveBase } from "../../styles/common";

import { LeadContainer as LeadBase } from "../../leads/common/lead.styles";

const variables = css`
  --offset-y: 0.364583333vw;
  --offset-x: 0.208333333vw;
`;

export const LineRight = styled(Separator.Root)`
  flex-grow: 1;
  flex-shrink: 0;
  height: 0.5vw;
  background: var(--color);
`;

export const LeadCategoryContainer = styled(LeadBase)`
  flex-direction: column;
  padding-bottom: var(--root-vertical-space-2);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    /* padding-bottom: var(--root-vertical-space-2); */
    clip-path: inset(0 -100vw -100vw -100vw);
  }
`;

export const Folder = styled.div`
  display: flex;
  justify-content: flex-start;
  background: white;
  align-items: center;
  ${variables};
  width: 100%;
  margin-bottom: var(--root-vertical-space-2);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    justify-content: center;
    align-self: start;
    width: 12.395833333vw;

    transform: skewY(15deg);

    & > * {
      transform: skewY(-15deg);
      margin-top: min(6.25vw, 120px);
    }

    height: 72%;
    border-radius: 0 0 2vw 2vw;
    position: relative;
    top: calc((var(--offset-y) + 12.395833333vw / 2 * 0.26794919243) * -1);

    box-shadow: var(--offset-x) var(--offset-y) 0
      var(--root-background-color-default);
  }
`;

export const MoveContainer = styled(MoveBase)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

export const CategoryTitleContainer = styled.div`
  background: var(--root-background-color-white-1);
  text-align: left;
  padding: calc(var(--root-vertical-space-2) + var(--root-top-offset-video)) 0
    var(--root-vertical-space-1) 5.1vw;

  ${Folder} & {
    background: none;
    padding: 0 var(--root-horizontal-space-3) 0 var(--root-horizontal-space-2);
  }
`;

export const CategoryDetailsContainer = styled.div`
  padding: 1.25rem min(5.1vw, 87.92px) 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CategoryDescription = styled.div`
  flex-basis: 60ch;
  text-align: left;
`;
