import styled from "styled-components/macro";
import {
  boxShadowSharp,
  centeringAbsolutePositionContainer,
} from "../../styles/common";

import { MEDIA_QUERY_LIMITS } from "../../../config";

export const LeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--height, var(--root-lead-height));
  background: var(--backgroundColor, white);
`;

export const EpisodeVideoContainer = styled.div`
  width: var(--width, var(--root-video-player-width));

  @media screen and (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    width: var(--width, 95%);
  }

  border-radius: var(--border-radius, var(--root-border-radius-1));
  overflow: hidden;
  ${boxShadowSharp};
  z-index: 1;
  position: relative;
  border: var(--border, 1px) solid rgba(255, 255, 255, 0.8);
  background: black;
`;

export const EpisodeTitleOnVideoContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 60px;
  width: 100%;
  text-align: center;
  color: white;
`;

export const EpisodeUtrendersContainer = styled.div`
  --offset-y: calc(
    (
        var(--root-utrender-card-width-bis) - var(
            --root-icon-utrender-width-small
          )
      ) / 2 - 0.260416667vw
  );

  --utrender-shadow-width: calc(0.5vw + 16px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  position: relative;
  height: var(--height, auto);
  right: var(--offset-y);
  width: calc(
    var(--root-utrender-card-width-bis) + var(--offset-y) +
      var(--utrender-shadow-width)
  );

  &:before {
    content: "";
    ${centeringAbsolutePositionContainer};
    left: calc(var(--offset-y) * -2);
    width: 100%;
    height: var(--pseudoHeight, 100%);
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
`;

export const EpisodeUtrendersMobileContainer = styled.div`
  margin-top: var(--root-vertical-space-0-rem);
  border-top: 3px solid var(--secondaryColor);
`;
