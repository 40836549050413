import styled, { css } from "styled-components/macro";

import { MEDIA_QUERY_LIMITS } from "../../../config";
import {
  centeringContainer,
  boxShadowSharp,
  boxShadow,
} from "../../styles/common";
import { LeadProgramContainer } from "../../leads/lead-program/lead-program.styles";
import { LeadProgramBlueHorizonsContainer } from "../../leads/lead-blue-horizons-program/lead-blue-horizons-program.styles";
import { EpisodeUtrendersMobileContainer } from "../../leads/common/lead.styles";
import { PersonIcon as PersonSvg } from "@radix-ui/react-icons";

export const PersonIcon = styled(PersonSvg)`
  width: auto;
  border: 1px solid black;
  border-radius: 50%;
  background: var(--root-background-color-header-desktop);
`;

const cursorBehaviours = css`
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;

export const UtrenderCardSlantContainer = styled.div`
  align-items: center;
  background: white;
  border: 1px solid rgba(150, 150, 150, 0.1);
  box-shadow:
    0.5vw 0 0 var(--cardBorderColor, var(--primaryColor)),
    0 6px 6px hsl(0deg 0% 0% / 0.3);
  display: flex;
  flex-direction: column;
  gap: 0.8vw;
  justify-content: center;
  overflow: hidden;
  transform: skew(-8deg);
  min-height: 100%; /* in order to have all the same height in swiper Cfr. Swiper.js docs */
  height: var(--root-utrender-card-height);
  width: var(--root-utrender-card-width);

  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthDesktop}px) {
    box-shadow:
      0.5vw 0 0 var(--cardBorderColor, var(--primaryColor)),
      0 6px 6px hsl(0deg 0% 0% / 0.3),
      calc(0.5vw + 10px) 10px 10px hsl(0deg 0% 0% / 0.5);
  }

  & > * {
    transform: skew(8deg);
  }

  /*
    When card is in the lead container we want it smaller
  */
  ${LeadProgramBlueHorizonsContainer} &,
  ${LeadProgramContainer} & {
    width: var(--root-utrender-card-width-bis);
    height: var(--root-utrender-card-height-bis);
  }

  ${EpisodeUtrendersMobileContainer} {
    border: unset;
    box-shadow: none;
  }

  ${cursorBehaviours}
`;

const cssSharedBetweenPlainAndB2B = css`
  align-items: center;
  display: flex;

  ${cursorBehaviours}
`;

const cssSharedBetweenB2BAndBlueHorizonsPicture = css`
  width: var(--root-icon-utrender-width);
  height: var(--root-icon-utrender-height);

  img {
    border-radius: 50%;
  }
`;

export const UtrenderCardPlainContainer = styled.div`
  ${cssSharedBetweenPlainAndB2B};
`;

export const UtrenderCardB2BContainer = styled.div`
  ${cssSharedBetweenPlainAndB2B};

  flex-direction: column;
  height: var(--root-utrender-card-height);
  width: var(--root-utrender-card-width);
  @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    margin: 0 auto;
  }
`;

export const PeopleCardBlueHorizonsContainer = styled.div`
  ${cssSharedBetweenPlainAndB2B};
  flex-direction: column;
  justify-content: center;
  min-height: 100%; /* in order to have all the same height. Cfr. Swiper.js docs */
  height: calc(var(--root-utrender-card-height) - 1.5vw);
  width: calc(var(--root-utrender-card-width) - 1.5vw);
`;

export const UtrenderPicture = styled.div`
  ${centeringContainer}
  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
  }

  img:not([src]) {
    visibility: hidden;
  }

  ${UtrenderCardSlantContainer} & {
    width: var(--root-icon-utrender-width);
    height: var(--root-icon-utrender-height);

    img {
      border-radius: 50%;

      @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
        ${boxShadowSharp};
      }
    }
  }

  ${UtrenderCardPlainContainer} & {
    width: var(--root-icon-utrender-width-small-unbound);
    height: var(--root-icon-utrender-height-small-unbound);
    border-radius: var(--root-border-radius-0);
    overflow: hidden;

    ${boxShadow};
  }

  /*
    When in lead container the user image should be smaller
  */
  ${LeadProgramBlueHorizonsContainer} ${UtrenderCardSlantContainer} &,
  ${LeadProgramContainer} ${UtrenderCardSlantContainer} & {
    width: var(--root-icon-utrender-width-small);
    height: var(--root-icon-utrender-height-small);
  }

  ${UtrenderCardB2BContainer} & {
    ${cssSharedBetweenB2BAndBlueHorizonsPicture}
  }

  ${PeopleCardBlueHorizonsContainer} & {
    ${cssSharedBetweenB2BAndBlueHorizonsPicture}
    img {
      border: max(0.416666667vw, 4px) solid var(--cardBorderColor);
    }
  }
`;

export const UtrenderName = styled.p`
  font-size: var(--h4-size);
  font-style: italic;
  font-weight: var(--root-font-weight-bold);
  padding-left: min(1.5vw, 23px);

  span {
    display: block;
    line-height: 1.1;
    padding-bottom: 0;
  }

  ${UtrenderCardPlainContainer} & {
    margin: 0;
    padding: var(--root-horizontal-space-1);
    font-size: var(--h3-size);
    font-weight: var(--root-font-weight-bold);
    font-style: normal;

    span {
      display: inline;
    }
  }

  ${UtrenderCardB2BContainer} & {
    padding: var(--root-horizontal-space-1) 0;
  }

  ${EpisodeUtrendersMobileContainer} & {
    color: var(--secondaryColor);
  }

  &:hover {
    cursor: pointer;
  }

  ${PeopleCardBlueHorizonsContainer} & {
    display: none;
  }
`;
